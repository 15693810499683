<template lang="pug">
.mb-6.mb-md-0
  wizard-top.mb-4.mb-md-5(:show-progress="false")
  agency-contact-form(
    :title="$t('onboarding.agencyDetails.title')"
    :subTitle="$t('onboarding.agencyDetails.subTitle')"
  )
</template>

<script>
  import WizardTop from '@/components/Wizard/Top.vue';
  import AgencyContactForm from '@/views/Onboarding/components/AgencyContactForm.vue';

  export default {
    components: { WizardTop, AgencyContactForm },
  };
</script>
